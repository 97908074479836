<template>
  <validation-observer ref="form" v-slot="{ invalid }" slim>
    <layout width="420">
      <template slot="header">
        Program settings
      </template>

      <template>
        <v-form>
          <form-subheader title="Map" />
          <v-select
            v-model="getById('programMap').value"
            :items="maps"
            label="Default map"
            item-text="name"
            item-value="id"
          />

          <form-subheader title="Style" />
          <v-select v-model="theme" :items="themes" label="Theme" disabled />
          <v-select
            v-model="getById('programSizeOfObjectsOnMap').value"
            disabled
            :items="sizes"
            label="Size of objects on map"
          />
          <v-select
            v-model="getById('programSizeOfObjectCardOnMap').value"
            disabled
            :items="sizes"
            label="Size of object card on map"
          />
          <v-select
            v-model="language"
            :items="languages"
            label="Language"
            disabled
          />

          <form-subheader title="Content" />

          <v-select
            v-model="getById('programDefaultGeotag').value"
            :items="geotags"
            item-text="name"
            item-value="id"
            clearable
            label="Geotag"
          />
          <v-select
            v-model="getById('programDefaultObjectGroup').value"
            :items="objectGroups"
            item-text="name"
            item-value="id"
            clearable
            label="Group"
          />

          <form-switcher
            v-model="getById('programAllowAllGeotags').value"
            title="Allow ALL"
          />
          <div class="caption mb-1 mt-1">
            If you disable the ALL option, only objects and plans that have been
            added to geotags will remain available to you.
          </div>

          <form-subheader title="Default position" />

          <validation-provider
            v-slot="{ errors }"
            name="Center latitude"
            rules="double"
          >
            <v-text-field
              v-model="getById('programDefaultPosition').value[1]"
              :error-messages="errors"
              label="Center latitude"
              clearable
            />
          </validation-provider>
          <validation-provider
            v-slot="{ errors }"
            name="Center longitude"
            rules="double"
          >
            <v-text-field
              v-model="getById('programDefaultPosition').value[0]"
              :error-messages="errors"
              label="Center longitude"
              clearable
            />
          </validation-provider>
          <validation-provider
            v-slot="{ errors }"
            name="Default scale"
            rules="double"
          >
            <v-text-field
              v-model="getById('programDefaultRotation').value"
              :error-messages="errors"
              label="Rotation(deg)"
            />
          </validation-provider>
          <form-subheader title="Default scale" />

          <v-slider
            v-model="getById('programDefaultScale').value"
            thumb-label
            max="28"
            min="0"
            type="number"
            append-icon="mdi-magnify-plus-outline"
            prepend-icon="mdi-magnify-minus-outline"
            @change="handleZoom"
          />
          <v-btn outlined color="primary" block @click="setCurrentMap">
            Use current map parameters
          </v-btn>
          <v-btn
            outlined
            color="primary"
            class="mt-4"
            block
            @click="setDefault"
          >
            Set default parameters
          </v-btn>
        </v-form>
      </template>
      <template slot="footer">
        <v-spacer />
        <v-btn text color="text-primary" @click.stop="close">
          Cancel
        </v-btn>
        <v-btn
          text
          color="primary"
          depressed
          :loading="isLoading"
          :disabled="isLoading || invalid"
          @click="submit"
        >
          Save
        </v-btn>
      </template>
    </layout>
  </validation-observer>
</template>

<script>
import Layout from '@/components/popup/PopupLayoutDefault';
import useProgramSettings from '@/modules/account/useProgramSettings';
import useGeneralSettings from '@/modules/settings/general/useGeneralSettings';
import { useMap } from '@/compositions/map';
import { onMounted, ref } from '@vue/composition-api';
import { useGeotags } from '@/modules/geotags/compositions/geotags';
import { useObjectGroups } from '@/modules/object-groups/compositions/object-groups';
import { extend, ValidationObserver, ValidationProvider } from 'vee-validate';
import { double } from 'vee-validate/dist/rules';

extend('double', double);

export default {
  name: 'ProgramSettings',
  components: {
    Layout,
    ValidationObserver,
    ValidationProvider
  },
  setup(props, context) {
    const {
      getCenter,
      setZoom,
      mapZoomIn,
      mapZoomOut,
      getZoom,
      setDefaultCenter,
      setDefaultZoom
    } = useMap();
    const programSettings = useProgramSettings(props, context);
    const generalSettings = useGeneralSettings(props, context);
    const { list: geotags } = useGeotags();
    const { objectGroupList: objectGroups } = useObjectGroups();
    const isLoading = ref(false);

    const zoomIn = () => {
      mapZoomIn();
      programSettings.getById('programDefaultScale').value = getZoom();
    };
    const zoomOut = () => {
      mapZoomOut();
      programSettings.getById('programDefaultScale').value = getZoom();
    };

    const setCurrentMap = () => {
      handleGetCenter();
      handleGetZoom();
    };

    const setDefault = () => {
      const defaultCoords = generalSettings.getById('programDefaultPosition')
        .defaultValue;
      const defaultZoom = generalSettings.getById('programDefaultScale')
        .defaultValue;
      programSettings.getById('programDefaultScale').value = defaultZoom;
      programSettings.getById('programDefaultPosition').value = defaultCoords;

      setDefaultCenter(defaultCoords);
      handleZoom(defaultZoom);
    };

    const handleGetCenter = () => {
      let coords = getCenter();
      programSettings.centerPosition.value = coords;
      programSettings.getById(
        'programDefaultPosition'
      ).value = coords.map(item => item.toFixed(6));
    };

    const handleGetZoom = () => {
      programSettings.getById('programDefaultScale').value = getZoom();
    };

    const handleZoom = value => {
      setZoom(value);
    };

    onMounted(() => {
      programSettings.load();
    });

    const submit = async () => {
      try {
        isLoading.value = true;
        const defaultCoords = programSettings.getById('programDefaultPosition')
          .value;
        const defaultZoom = programSettings.getById('programDefaultScale')
          .value;
        setDefaultCenter(defaultCoords, true);
        setDefaultZoom(defaultZoom, true);
        await programSettings.updateSettings();
      } finally {
        isLoading.value = false;
      }
    };
    return {
      ...programSettings,
      zoomIn,
      zoomOut,
      handleGetCenter,
      handleZoom,
      handleGetZoom,
      setCurrentMap,
      setDefault,
      submit,
      objectGroups,
      geotags,
      isLoading
    };
  }
};
</script>
